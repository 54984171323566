export default function($) {
  $('.copy-button').on('click', function(e) {
    e.preventDefault();
    const $couponInput = $(this).find('input.copy-value[type=text]');
    if ($couponInput.length < 1) {
      throw new Error('Coupon hidden input nof found');
    }
    const couponInput = $couponInput[0];
    if (couponInput.value.length < 1) {
      throw new Error('Coupon value is empty');
    }
    couponInput.select();
    document.execCommand('copy');
    alert(couponInput
      .getAttribute('data-copied-alert-message')
      .replace('@value', couponInput.value)
    );
  });
}
