export default function($) {

  (function () {
    const pageNavToggle = document.querySelectorAll('.lp-nav-toggle');
    if (pageNavToggle) {
      pageNavToggle.forEach(el => {
        el.addEventListener('click', (e) => {
          const self = document.querySelector('.root');
          self.classList.toggle('nav-open');
          return false;
        });
      });
    }
  })();

  $(function() {
    let $lpAdvantageSlider = $('.lp-advantages-slider');
    $lpAdvantageSlider.owlCarousel({
      merge: true,
      margin: 12,
      loop: true,
      navigation : false,
      responsiveClass:true,
      responsive: {
        0:{
          items: 1,
          margin: 8
        },
        576: {
          items: 2,
          margin: 16,
        },
        768: {
          items: 2,
          margin: 24,
        },
        1024: {
          items: 2,
          margin: 48,
        },
        1200: {
          items: 3,
          margin: 75,
        },
      }
    });

    $('.lp-advantages__nav--prev').on('click', function(e) {
      e.preventDefault();
      $lpAdvantageSlider.trigger('prev.owl.carousel');
    })

    $('.lp-advantages__nav--next').on('click', function(e) {
      e.preventDefault();
      $lpAdvantageSlider.trigger('next.owl.carousel');
    })
  });

  $(function() {
    let $lpCertSlider = $('.lp-cert-slider');
    $lpCertSlider.owlCarousel({
      merge: true,
      margin: 12,
      loop: true,
      navigation : false,
      responsiveClass:true,
      responsive: {
        0:{
          items: 1,
          margin: 8
        },
        576: {
          items: 2,
          margin: 16,
        },
        768: {
          items: 2,
          margin: 24,
        },
        1024: {
          items: 3,
          margin: 24,
        },
        1200: {
          items: 3,
          margin: 75,
        },
      }
    });

    $('.lp-cert__nav--prev').on('click', function(e) {
      e.preventDefault();
      $lpCertSlider.trigger('prev.owl.carousel');
    })

    $('.lp-cert__nav--next').on('click', function(e) {
      e.preventDefault();
      $lpCertSlider.trigger('next.owl.carousel');
    })
  });

  $(function() {
    let $lpServiceSlider = $('.lp-services-slider');
    $lpServiceSlider.owlCarousel({
      merge: true,
      margin: 12,
      loop: false,
      dots: false,
      navigation : false,
      responsiveClass:true,
      responsive: {
        0:{
          items: 1,
          margin: 12
        },
        576: {
          items: 2,
          margin: 16,
        },
        768: {
          items: 2,
          margin: 24,
        },
        1024: {
          items: 3,
          margin: 24,
        },
        1200: {
          items: 4,
          margin: 24,
        },
      }
    });
  });

  $(function() {
    let $lpStructureSlider = $('.lp-structure-slider');
    $lpStructureSlider.owlCarousel({
      merge: true,
      margin: 12,
      loop: false,
      dots: false,
      navigation : false,
      responsiveClass:true,
      responsive: {
        0:{
          items: 1,
          margin: 12
        },
        576: {
          items: 2,
          margin: 16,
        },
        768: {
          items: 2,
          margin: 24,
        },
        1024: {
          items: 4,
          margin: 24,
        },
        1200: {
          items: 4,
          margin: 24,
        },
      }
    });
  });

  $(function() {
    let $lpBusinessSlider = $('.lp-business-slider');
    $lpBusinessSlider.owlCarousel({
      margin: 12,
      loop: true,
      merge:true,
      dots: false,
      navigation : false,
      responsiveClass:true,
      responsive: {
        0:{
          items: 1,
          margin: 12,
          mergeFit:true
        },
        576: {
          items: 2,
          margin: 16,
        },
        768: {
          items: 2,
          margin: 24,
          mergeFit:true
        },
        1024: {
          items: 3,
          margin: 24,
        },
        1200: {
          items: 3,
          margin: 75,
        },
      }
    });

    $('.lp-business__nav--prev').on('click', function(e) {
      e.preventDefault();
      $lpBusinessSlider.trigger('prev.owl.carousel');
    })

    $('.lp-business__nav--next').on('click', function(e) {
      e.preventDefault();
      $lpBusinessSlider.trigger('next.owl.carousel');
    })
  });
}
