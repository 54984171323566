import getSerializedFormAsObject from "./getSerializedFormAsObject";

function parseUrlParams(url, bParseHash) {
  bParseHash = !!bParseHash;
  let oTmpUrlHash = url.split('#');
  let paramsString = '';
  if(bParseHash && oTmpUrlHash[1]) {
    paramsString = oTmpUrlHash[1];
  }
  else if(oTmpUrlHash[0]) {
    paramsString = oTmpUrlHash[0];
  }
  if (!bParseHash) {
    const splitQuery = paramsString.split('?');
    if (splitQuery[1]) {
      paramsString = splitQuery[1];
    }
  }
  paramsString = paramsString.replace(/^\??/, '&');
  let parser = /(?:^|&|\?)([^&=]+)=?([^&]*)/g;
  let rawUrlParams = [];
  paramsString.replace(parser, function($0, $1, $2, $3, $4) {
    //onsole.log($1, $2);
    if($1) {
      rawUrlParams.push({name: $1, value: $2});
    }
  });
  return getSerializedFormAsObject(rawUrlParams);
}

export default parseUrlParams;
