import mergeDataOpts from '../lib/merge-data-opts';

export default function($) {
  $(function() {
    $('[data-toggle=owl-carousel]').each(function() {
      const $carousel = $(this);
      const data = $carousel.data();
      const options = mergeDataOpts('owl', {}, data, {});
      $carousel.owlCarousel(options);
    });
  });
};
