import getSerializedFormAsObject from '../lib/getSerializedFormAsObject';

export default function($) {
  $.fn.bxWebFormAjaxSubmit = function({onAddOk, onUpdateForm}) {
    this.each(function() {
      const $form = $(this);
      const ajaxCallAction = $form.attr('data-ajax-action');
      if (!ajaxCallAction) return;
      $form.on('submit', function(e) {
        e.preventDefault();
        const formData = getSerializedFormAsObject(
          $form.serializeArray().concat(
            $form.find('input[type=hidden]').serializeArray()
          )
        );
        function updateForm(data, $form) {
          $form.html(data);
          if (typeof onUpdateForm === 'function') {
            onUpdateForm(data, $form);
          }
        }
        $.post(ajaxCallAction, formData)
          .done(function(data, result, response) {
            // const $inputs = $form.find('textarea, input[type=text], input[type=email]');
            // $inputs.val('').trigger('change');
            if (result !== 'success') {
              console.error(result, data);
              return;
            }
            if (typeof response.responseJSON !== 'undefined') {
              if (data.result === 'addok') {
                if (typeof onAddOk === 'function') {
                  onAddOk(data, $form);
                }
                const $signedParamsInput = $form.find('input[type=hidden][name=signedParamsString]');
                const $templateInput = $form.find('input[type=hidden][name=template]');

                $.post({
                  url: ajaxCallAction,
                  data: {
                    web_form_submit: 'Y',
                    ...(($signedParamsInput.length > 0)
                      ? {signedParamsString: $signedParamsInput.val()}
                      : {}
                    ),
                    ...(($templateInput.length > 0)
                      ? {template: $templateInput.val()}
                      : {}
                    )
                  },
                  success: function(data) {
                    updateForm(data, $form);
                  }
                })
              } else {
                console.error(data);
              }
            } else {
              updateForm(data, $form);
            }
          })
          .fail(function(error) {
            console.error(error);
          });
        return false;
      });
    });
  };
};
