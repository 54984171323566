import textInputBoxPlugin from "../jquery-plugins/text-input-box";
import numberInputBoxPlugin from "../jquery-plugins/number-input-box";
import limitContentHeightJqueryPlugin from "../jquery-plugins/limit-content-height";
import responsiveCarouselSwitcherPlugin from "../jquery-plugins/responsive-carousel-switcher";
import multiInputBoxPlugin from "../jquery-plugins/multi-input-box";
import bxWebFormAjaxSubmitPlugin from "../jquery-plugins/bx-web-form-ajax-submit";

export default function($) {
  textInputBoxPlugin($);
  numberInputBoxPlugin($);
  limitContentHeightJqueryPlugin($);
  responsiveCarouselSwitcherPlugin($);
  multiInputBoxPlugin($);
  bxWebFormAjaxSubmitPlugin($);

  $(function() {
    $('.input-field:not(.input-field_manual-init)').textInputBox('input-field');
    $('[data-limit-content-height]').limitContentHeight();
    $('[data-toggle="popover"]').popover();
    $('input[type=number].number-input-box').numberInputBox();
    $('.multi-input-field:not(.multi-input-field_manual-init)').multiInputBox({
      multiInputBoxClass: 'multi-input-field',
      inputBoxClass: 'input-field'
    });
  });
}
