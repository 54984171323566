export function behaviourRowModule($) {
  $(function() {
    const $rowModuleSlider = $('.row-module-slider');
    $rowModuleSlider.owlCarousel({
      merge: true,
      loop: true,
      nav:false,
      responsiveClass:true,
      responsive:{
        0:{
          items: 1,
          margin: 8,
          autoWidth: true,
        },
        576: {
          items: 2,
          margin: 8,
          autoWidth: true,
        },
        768:{
          items: 3,
          margin: 16,
          autoWidth: true,
        },

        992:{
          items: 4,
          margin: 16,
          autoWidth: true,
        },
        1260:{
          items: 5,
          margin: 33
        }
      }
    });

    $('.row-module-next').on('click', function(e) {
      e.preventDefault();
      $rowModuleSlider.trigger('next.owl.carousel');
    });

    $('.row-module-prev').on('click', function(e) {
      e.preventDefault();
      $rowModuleSlider.trigger('prev.owl.carousel', [300]);
    });
  });
}

export default behaviourRowModule;
