import mergeDataOpts from '../lib/merge-data-opts';
import replaceOptsMacros from "../lib/replace-opts-macros";

export default function ($) {
  $.fn.responsiveCarouselSwitcher = function(opts) {
    const defaults = {
      block: null,
      row: '.@{block}__row',
      item: '.@{block}__col',
      breakpoint: 768,
      owl: {}
    };
    const dataBlockInitKey = 'responsiveCarouselSwitcherInitialized';
    const $window = $(window);
    let sliders = [];

    function resize() {
      sliders.forEach(function(slider) {
        if ($window.width() < slider.options.breakpoint) {
          if(!slider.isSmall) {
            slider.isSmall = true;
            startCarousel(slider);
          }
        } else {
          if(slider.isSmall) {
            slider.isSmall = false;
            stopCarousel(slider);
          }
        }
      });
    }

    function startCarousel(slider) {
      if(slider.active) return;
      if (!slider.hasInitialOwlCarouselClass) {
        slider.$row.addClass('owl-carousel');
      }
      const owlOptions = mergeDataOpts('owl', {}, slider.$row.data(), slider.options.owl);
      slider.$row.owlCarousel(owlOptions);
      slider.active = true;
    }

    function stopCarousel(slider) {
      if(!slider.active) return;
      slider.$items.detach();
      slider.$row.trigger('destroy.owl.carousel');
      slider.$row.html('');// ��������� �������
      slider.$row.removeClass('owl-carousel');
      slider.$row.append(slider.$items);
      slider.active = false;
    }

    function getRows($block, rowSelector) {
      const $rows = $block.find(`${rowSelector}`);
      if ($rows.length < 1) {
        throw new Error(`Carousel row element not found (${rowSelector})`);
      }
      return $rows;
    }

    function getItems($row, itemSelector) {
      const $items = $row.find(`${itemSelector}`);
      if ($items.length < 1) {
        throw new Error(`Carousel items not found (${itemSelector})`);
      }
      return $items;
    }

    this.each(function() {
      const $block = $(this);
      const data = $block.data();
      if (typeof data[dataBlockInitKey] !== 'undefined') {
        return;
      }
      $block.data(dataBlockInitKey, true);
      const options = mergeDataOpts('responsiveCarouselSwitcher', defaults, data, opts);
      const classes = this.className.split(/\s+/);
      if (typeof options.block == 'undefined' || !options.block) {
        if( classes.length === 1 ) {
          options.block = classes[0];
        } else {
          throw new Error('Option "block" is required if selected block contains more then one class name definition');
        }
      }
      replaceOptsMacros(options);

      const $rows = getRows($block, options.row);
      $rows.each(function() {
        const $row = $(this);
        const $items = getItems($row, options.item);
        sliders.push({
          active: false,
          options: options,
          hasInitialOwlCarouselClass: $row.hasClass('owl-carousel'),
          isSmall: false,
          $block: $block,
          $row: $row,
          $items: $items
        });
      });
    });

    $window.resize(resize);
    resize();
  };
};
