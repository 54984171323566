export default function($) {

  $.fn.numberInputBox = function() {
    this.each(function() {
      const $input = $(this);
      const $inputContainer = $input
        .wrap('<div class="quantity__input"></div>')
        .parent('.quantity__input');
      const $block = $inputContainer
        .wrap('<div class="quantity"></div>')
        .parent('.quantity');

      $('<div class="quantity__button quantity__button-down"></div>').insertBefore($inputContainer);
      $('<div class="quantity__button quantity__button-up"></div>').insertAfter($inputContainer);

      const
        $btnUp = $block.find('.quantity__button-up'),
        $btnDown = $block.find('.quantity__button-down'),
        min = $input.attr('min'),
        max = $input.attr('max');

      $btnUp.click(function() {
        const oldValue = parseFloat($input.val());
        const newVal = (oldValue >= max)
          ? oldValue : oldValue + 1;
        $block.find("input").val(newVal);
        $block.find("input").trigger("change");
      });

      $btnDown.click(function() {
        const oldValue = parseFloat($input.val());
        const newVal = (oldValue <= min)
          ? oldValue : oldValue - 1;
        $block.find("input").val(newVal);
        $block.find("input").trigger("change");
      });

      $input.on('change',function(){
        if(+$(this).val() > max) {
          $(this).val(max);
        }
      });
    });
  };
};
