export default function replaceOptsMacros(options, keys) {
  if (!Array.isArray(keys)) keys = Object.keys(options);
  keys.forEach(function(key) {
    if (options.hasOwnProperty(key)) {
      if (typeof options[key] === 'string'
        && /@{[^}]+}/.test(options[key])
      ) {
        //onsole.log(`options[${key}]:`, options[key]);
        keys.forEach(function(refKey) {
          if (options.hasOwnProperty(refKey) && options[refKey]) {
            options[key] = options[key].replace(
              new RegExp(`@{${refKey}}`),
              options[refKey]
            );
          }
        });
      }
    }
  });
  return options;
};
