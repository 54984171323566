export default function($) {
  $.fn.multiInputBox = function({multiInputBoxClass, inputBoxClass}) {
    if (!multiInputBoxClass) multiInputBoxClass = 'multi-input-field';
    if (!inputBoxClass) inputBoxClass = 'input-field';
    this.each(function() {
      const $multiInputContainer = $(this);
      if ($multiInputContainer.find(`.${multiInputBoxClass}__btn-add`).length < 1) {
        $multiInputContainer.append(`<button class="${multiInputBoxClass}__btn-add"></button>`);
      }
      let index = $multiInputContainer.find('input, textarea').length;
      const nameTemplate = $multiInputContainer.data('input-name');
      const placeholderTemplate = $multiInputContainer.data('inputPlaceholder');
      const inputType = $multiInputContainer.data('inputType') === 'textarea' ? 'textarea' : 'input';
      const textAreaRows = (inputType === 'textarea')
        ? parseInt($multiInputContainer.data('textarea-rows')) : 0;

      $multiInputContainer.on('click', `.${multiInputBoxClass}__btn-add`, function(e) {
        e.preventDefault();
        const $btnAdd = $(this);
        const name = nameTemplate
          .replace('@{index}', index)
          .replace('@{number}', index+1);
        const placeholder = placeholderTemplate
          .replace('@{index}', index)
          .replace('@{number}', index+1);
        const required = $multiInputContainer.data('input-required');
        const $newInput = $(`
          <div class="${inputBoxClass} ${inputBoxClass}_textarea"
               data-index="${index}">
            <button class="${multiInputBoxClass}__btn-remove"></button>`
            +((inputType === 'textarea')
              ? `
              <textarea name="${name}" placeholder="${placeholder}"`+
                (textAreaRows > 0 ? ` rows="${textAreaRows}"` : '')+
                (required ? ` required="required"` : '')+`></textarea>`
              : `
              <input type="text" name="${name}" placeholder="${placeholder}"`+
                (required ? ' required="required"' : '')+`>`
            )+`
          </div>`
        );
        $newInput.insertBefore($btnAdd);
        $newInput.textInputBox(inputBoxClass);
        index++;
      });

      $multiInputContainer.on('click', `.${multiInputBoxClass}__btn-remove`, function(e) {
        e.preventDefault();
        const $item = $(this).parent();
        const currentIndex = parseInt($item.data('index'));
        if (currentIndex === (index - 1)) {
          index--;
        }
        $item.remove();
        if ($multiInputContainer.find(`.${inputBoxClass}`).length < 1) {
          index = 0;
        }
      });

    });
  };
}
