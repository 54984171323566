export default function($) {
  $('.input-field-passview').on('click', function(){
    let $passField = $($(this).closest('.input-field').find('input'));
    if ($passField.attr('type') === 'password'){
      $(this).addClass('view');
      $passField.attr('type', 'text');
    } else {
      $(this).removeClass('view');
      $passField.attr('type', 'password');
    }
    return false;
  });
}
