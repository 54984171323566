export default function($) {
  $.fn.textInputBox = function (inputBoxClass) {
    if (!inputBoxClass) inputBoxClass = 'input-field';
    this.each(function (index, element) {
      const $box = $(this);
      const $input = $box.find('input, textarea');
      if ($input.attr('required') && !$box.hasClass(`${inputBoxClass}_required`)) {
        $box.addClass(`${inputBoxClass}_required`);
      }
      if ($input.attr('placeholder')) {
        $box.attr('data-placeholder', $input.attr('placeholder'));
      }
      hasValue();
      if ($input[0] === document.activeElement) {
        $box.addClass(`${inputBoxClass}_focus`);
      }
      $input.on('change', hasValue);
      $input.on('focus', function () {
        $box.addClass(`${inputBoxClass}_focus`);
        hasValue();
      }).on('blur', function () {
        $box.removeClass(`${inputBoxClass}_focus`);
        hasValue();
      });

      function hasValue() {
        const hasValueClass = `${inputBoxClass}_has-value`;
        if ($input.val().length > 0) {
          if (!$box.hasClass(hasValueClass)) $box.addClass(hasValueClass);
        } else {
          if ($box.hasClass(hasValueClass)) $box.removeClass(hasValueClass);
        }
      }
    });
  }
};
