import parseUrlParams from './lib/parseUrlParams';
import getSerializedFormAsObject from './lib/getSerializedFormAsObject';

import behaviourSelectric from './behaviour/selectric';
import behaviourOwlCarousel from './behaviour/owl-carouisel';
import behaviourPasswordInputEye from './behaviour/password-input-eye';
import behaviourJqueryPlugins from './behaviour/jquery-plugins';
import behaviourRatyPlugin from './behaviour/raty';
import behaviourGreenLanding from './behaviour/green-landing';
import behaviourRowModule from './behaviour/row-module';
import behaviourBtnCopyToClipboard from './behaviour/btn-copy-to-clipboard';


if (typeof global.sunways_shop == 'undefined') global.sunways_shop = {};
global.sunways_shop.parseUrlParams = parseUrlParams;
global.sunways_shop.getSerializedFormAsObject = getSerializedFormAsObject;

behaviourRowModule(global.jQuery3);
behaviourSelectric(global.jQuery3);
behaviourOwlCarousel(global.jQuery3);
behaviourPasswordInputEye(global.jQuery3);
behaviourJqueryPlugins(global.jQuery3);
behaviourRatyPlugin(global.jQuery3);
behaviourGreenLanding(global.jQuery3);
behaviourBtnCopyToClipboard(global.jQuery3);
