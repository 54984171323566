import mergeDataOpts from '../lib/merge-data-opts';
import replaceOptsMacros from "../lib/replace-opts-macros";

export default function($) {
  const defaults = {
    block: 'limit-content-height',
    _: null, // height
    className: '@{block}',
    body: '@{block}__body',
    bodyCollapsed: '@{block}__body_collapsed',
    toggle: '@{block}__toggle',
    toggleCollapsed: '@{block}__toggle_collapsed',
    collapsed: true,
    captionCollapse: 'Collapse',
    captionExpand: 'Expand'
  };
  class Block {
    constructor(elt, opts) {
      this.options = opts;
      this.options = opts;
      this.config = {};
      this.$block = $(elt);
      this.$body = null;
      this.$existsContents = null;
      this.initialized = false;
      this.collapsed = false;
    }

    init() {
      if (this.initialized) return;
      const state = this;
      const $block = this.$block;
      const data = $block.data();
      const opts = this.options;
      $block.data('limitContentHeight_BlockObject', this);
      // merge options from data attributes and plugin options argument object
      const config = this.config = replaceOptsMacros(mergeDataOpts(
        'limitContentHeight', defaults, data, opts
      ));
      // use attr "data-content-limit-height" instead "data-content-limit-height-height"
      // after call mergeDataOpts() self attr-value placed in config._
      config.height = config._;
      state.collapsed = !!config.collapsed;
      if (!$block.hasClass(config.block)) {
        $block.addClass(config.block);
      }
      const $existsContents = this.$existsContents = $block.contents();
      $existsContents.detach();
      $block.append(`
        <div class="${config.body}${state.collapsed ? ' '+config.bodyCollapsed : ''}"
             ${config.height ? `style="max-height: ${config.height}"` : ''}></div>
        <a href="#" class="${config.toggle}">
            ${state.collapsed
        ? config.captionExpand
        : config.captionCollapse}
        </a>
      `);
      const $toggle = $block.find(`.${config.toggle}`);
      const $body = this.$body = $block.find(`.${config.body}`);
      $toggle.on('click', function(event) {
        event.preventDefault();
        const $toggle = $(this);
        state.collapsed = !state.collapsed;
        $toggle.html(state.collapsed
          ? config.captionExpand
          : config.captionCollapse);
        if (state.collapsed) {
          if (!$body.hasClass(config.bodyCollapsed))
            $body.addClass(config.bodyCollapsed);
          if (!$toggle.hasClass(config.toggleCollapsed))
            $toggle.addClass(config.toggleCollapsed);
          if (config.height)
            $body.css({maxHeight: config.height});
        } else {
          $body.removeClass(config.bodyCollapsed);
          $toggle.removeClass(config.toggleCollapsed);
          if (config.height) $body.removeAttr('style');
        }
        return false;
      });
      $block.find(`.${config.body}`).append($existsContents);
      this.initialized = true;
    }

    clear() {
      if (!this.initialized) return;
      this.$existsContents.detach();
      this.$block.html('');
      this.$block.append(this.$existsContents);
      this.$body = undefined;
      this.$existsContents = undefined;
      this.initialized = false;
    }
  }

  $.fn.limitContentHeight = function(opts) {

    let methodCall = null;
    if (typeof opts === 'string') {
      methodCall = opts;
      opts = {};
    }
    this.each(function() {
      let $block = $(this);
      let block = $block.data('limitContentHeight_BlockObject');
      if (typeof block !== 'undefined') {
        switch(methodCall) {
          case 'clear': block.clear(); return;
        }
      } else {
        if (methodCall !== null) {
          throw 'You should to initialize first, before using method-call';
        }
        block = new Block(this, opts);
      }
      block.init();
    });
  };
}
