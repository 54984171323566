function getParentNodeByNameChain(obFieldsTree, arNameChain, depth) {
  let parentNode = obFieldsTree;
  for(let index=1; index<=depth; index++) {
    //let dumpCurParent = parentNode;
    let typeOfParentNode = typeof(parentNode[arNameChain[index-1]]);
    switch(typeOfParentNode) {
      // undefined по идее недостижимый случай, но на всякий вожарный
      case 'undefined':
        parentNode[arNameChain[index-1]] = {__itemsCount__: 0};
        break;
      case 'string':
        //сохраняем уже существующее значение ноды, если передано значение дочернего элемента
        // ситуация вида key1=val1&key1[]=val11
        //Хотя тот же php не разруливает такое, а сразу заменяет на массив
        // и на выходе остается только key1 = array(0 => val11) // val1 - будет уничтожен
        //let stringParentNodeBackup = parentNode[arNameChain[index-1]];
        //parentNode[arNameChain[index-1]] = {__itemsCount__: 1, __backup__: stringParentNodeBackup};
        parentNode[arNameChain[index-1]] = {__itemsCount__: 0};
        break;
    }
    parentNode = parentNode[arNameChain[index-1]];
  }
  return parentNode;
}

function removeItemsCount(obTree) {
  for(let key in obTree) {
    if(!obTree.hasOwnProperty(key)) continue;
    if(key === '__itemsCount__') {
      delete obTree[key];
    }
    else if(obTree[key].constructor === Object) {
      removeItemsCount(obTree[key]);
    }
  }
  return obTree;
}

function getSerializedFormAsObject(arFormFields) {
  let obFieldsTree = {};
  for(let key in arFormFields) {
    if(!arFormFields.hasOwnProperty(key)) continue;
    let formParamName = arFormFields[key].name;
    let formParamValue = arFormFields[key].value;

    let arFormParamNameChain = formParamName.split('[');
    for(let depth=0; depth<arFormParamNameChain.length; depth++) {
      // removing last "]" from names
      if( arFormParamNameChain[depth][arFormParamNameChain[depth].length-1] === ']' ) {
        arFormParamNameChain[depth] = arFormParamNameChain[depth].substr(0, arFormParamNameChain[depth].length-1);
      }
      let curIndexName = arFormParamNameChain[depth];

      let parentNode = null;
      // making tree from name-chain
      if(arFormParamNameChain.length - depth === 1) {
        if(curIndexName === '') {
          parentNode = getParentNodeByNameChain(obFieldsTree, arFormParamNameChain, depth);
          parentNode.__itemsCount__++;
          parentNode[parentNode.__itemsCount__] = formParamValue;
        }
        else {
          parentNode = getParentNodeByNameChain(obFieldsTree, arFormParamNameChain, depth);
          parentNode[curIndexName] = formParamValue;
        }
        break;
      }
      else {
        parentNode = getParentNodeByNameChain(obFieldsTree, arFormParamNameChain, depth);
        if( parentNode[curIndexName] === undefined ) {
          parentNode[curIndexName] = {__itemsCount__: 0};
        }

      }
    }
  }
  removeItemsCount(obFieldsTree);
  return obFieldsTree;
}

export default getSerializedFormAsObject;
