export default function($) {
  $(function() {
    let $selectric = $('.selectric');
    $selectric.on('selectric-init', function(event, element, selectric) {
      //onsole.log('selectric-init', selectric);
      if (selectric.$element.attr('required')) {
        selectric.elements.outerWrapper.addClass('selectric-required');
      }
    });
    $selectric.selectric({
      arrowButtonMarkup: '<b class="button"></b>'
    });
  });
}
