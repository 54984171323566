export default function mergeDataOpts(dataPrefix, defaults, data, opts) {
  const options = {};
  let optKey = null;
  if (typeof opts != 'object' || opts === null) opts = {};
  for (let defKey in defaults) {
    if (defaults.hasOwnProperty(defKey)) {
      if (typeof options[defKey] === 'undefined' || options[defKey] === null) {
        options[defKey] = defaults[defKey];
      }
    }
  }
  for (let dataKey in data) {
    if (data.hasOwnProperty(dataKey)
      && dataKey.substring(0, dataPrefix.length) === dataPrefix
    ) {
      optKey = (dataPrefix === dataKey)
        ? '_'
        : dataKey[dataPrefix.length].toLowerCase()+dataKey.substring(dataPrefix.length+1);
      options[optKey] = typeof(data[dataKey]) === 'undefined'
        ? defaults[optKey] : data[dataKey]
    }
  }
  for (let optKey in opts) {
    options[optKey] = opts[optKey]
  }
  return options;
};
