import ratyPlugin from "../jquery-plugins/raty";

export default function($) {
  ratyPlugin($);
  $('.raty').raty({
    number: 5,
    starHalf      : 'star-half',
    starOff       : 'star-off',
    starOn        : 'star-on',
    cancelOff     : 'cancel-of',
    cancelOn      : 'cancel-on',
    score: function() {
      return $(this).attr('data-score');
    },
    readOnly: function() {
      return $(this).attr('data-readOnly');
    },
  });
};
